import React from 'react'
import { graphql } from 'gatsby'
import ImageWrap from 'components/ImageWrap'
// import BannerTitleOnlyBlock from 'components/blocks/BannerTitleOnlyBlock'
import BodyBlock from 'components/blocks/BodyBlock'
import OverlayItem from 'components/OverlayItem'

export default function DiamondsPage({ data }) {
  const page = data.strapiDiamondsPage
  const {
    // title,
    introBodyBlock,
    image1,
    diamondSet1,
    bodyBlock2,
    image2,
    diamondSet2,
    image3,
  } = page
  // const customBanner = { pretitle: title }
  return (
    <div className='page-diamonds'>
      {/* <BannerTitleOnlyBlock
        content={customBanner}
        padTop={true}
        lineBottom={true}
      /> */}
      <BodyBlock content={introBodyBlock} />
      <div className='pad-v-standard'>
        <Block1 content={{ image1, diamondSet1 }} />
        <Block2 content={{ bodyBlock2, image2 }} />
        <Block3 content={{ diamondSet2, image3 }} />
      </div>
    </div>
  )
}

const Block1 = ({ content }) => {
  const { image1, diamondSet1 } = content

  return (
    <div className='block-1 container'>
      <div className='image'>
        <ImageWrap image={image1.image} />
      </div>
      <div className='diamonds'>
        {diamondSet1.diamonds.map((item, i) => (
          <OverlayItem key={i} content={item} version='diamond' />
        ))}
      </div>
    </div>
  )
}

const Block2 = ({ content }) => {
  const { bodyBlock2, image2 } = content

  return (
    <div className='block-2 container'>
      <div className='text'>
        <BodyBlock content={bodyBlock2} />
      </div>
      <div className='image'>
        <ImageWrap image={image2.image} />
      </div>
    </div>
  )
}

const Block3 = ({ content }) => {
  const { diamondSet2, image3 } = content
  const diamondSet2a = diamondSet2.diamonds.slice(0, 2)
  const diamondSet2b = diamondSet2.diamonds.slice(2)

  return (
    <div className='block-3 container'>
      <div className='image-diamonds'>
        <div className='image'>
          <ImageWrap image={image3.image} />
        </div>
        <div className='diamonds diamonds-2a'>
          {diamondSet2a.map((item, i) => (
            <OverlayItem key={i} content={item} version='diamond' />
          ))}
        </div>
      </div>
      <div className='diamonds diamonds-2b'>
        {diamondSet2b.map((item, i) => (
          <OverlayItem key={i} content={item} version='diamond' />
        ))}
      </div>
    </div>
  )
}

export const pageQuery = graphql`
  query ($locale: String!) {
    strapiDiamondsPage(locale: { eq: $locale }) {
      title
      seo {
        title
        description
      }
      introBodyBlock {
        title
        body
      }
      image1 {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      diamondSet1 {
        diamonds {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 350)
              }
            }
          }
          title
          excerpt
          slug
        }
      }
      bodyBlock2 {
        title
        body
      }
      image2 {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      diamondSet2 {
        diamonds {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 350)
              }
            }
          }
          title
          excerpt
          slug
        }
      }
      image3 {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
