import React from 'react'
import MarkdownWrap from 'components/MarkdownWrap'
import Inview from 'components/Inview'

export default function BodyBlock({ content, isIntro }) {
  const { title, body } = content
  return (
    <section className='body-block pad-v-standard'>
      <div className='container'>
        <div className='text'>
          <div className='wrap'>
            {title && (
              <Inview className='fade-in up'>
                {isIntro && (
                  <h1
                    className='smaller'
                    dangerouslySetInnerHTML={{ __html: title }}
                  />
                )}
                {!isIntro && <h2 dangerouslySetInnerHTML={{ __html: title }} />}
              </Inview>
            )}
            {isIntro && (
              <Inview className='fade-in up'>
                <div className='border' />
              </Inview>
            )}
            {body && (
              <Inview className={`body fade-in up ${isIntro ? 'larger' : ''}`}>
                <MarkdownWrap body={body} />
              </Inview>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
